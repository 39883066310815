import React from "react";
import Markdown from "react-markdown"

import {getPageUrl} from '../lib/helpers'

import ArticleFooter from "../components/article-footer"
import ArticleHeader from "../components/article-header"
import ArticleWrapper from "../components/article-wrapper"
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import DynamicZoneComponent from '../components/dynamic-zone-component'
import OfferAffiliateNotice from "../components/offer-affiliatenotice"
import RelatedPages from '../components/related-pages'
import SearchBar from '../components/search-bar'
//import HeroAdSpace from "../components/hero-ad-space"
//import HeroMiniAdSpace from "../components/hero-mini-ad-space"

import * as styles from './blog-post.module.css'


function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}


function BlogPost ({article, metadata, search_index}) {

  var is_affiliate = []
  // loop through data and determine if there are affiliate offers
  // check list for offers
  if(article.body){
      for(let i = 0; i < article.body.length; i++){
          if (article.body[i].offers){
              for(let j = 0; j < article.body[i].offers.length; j++){
                is_affiliate.push(true)
              }
          }
      }
  }

  // devine section numbers if more than one
  var sectionNumber = 0
  var nth_ad = 0
  // 102 under_first_paragraph
  // 106 bottom_of_page
  var ad_ids = [
      118, // mid_content
      119, // long_content
      120, // longer_content
      121, // longest_content
      122, // incontent_5
      110, // incontent_6
      123, // incontent_7
      124, // incontent_8
      125, // incontent_9
      126, // incontent_10
      127  // incontent_11
  ]

  return (
    <>
        <ArticleHeader article={article}/>
            <ArticleWrapper article={article}>
                <div><Container>
                    <Markdown children={article.body_introduction} skipHtml={true} />
                </Container></div>
                    {
                        // display body components table of contents
                        article.body && article.body.length > 1 &&
                            <div><Container>
                                <span className={styles.toc}>Contents:</span>
                                <ul className={styles.toc_list}>
                                { article.body.map(component => component && (component.title || component.Question) && (
                                    // if there is a title, then add it to table of contents
                                    <li key={"shortcut_"+component.id}>
                                        { component.title &&
                                        <a href={getPageUrl(article)+"#"+string_to_slug(component.title)}>{component.title}</a>
                                        }
                                        { component.Question &&
                                        <a href={getPageUrl(article)+"#"+string_to_slug(component.Question)}>{component.Question}</a>
                                        }
                                    </li>
                                ))
                                }
                                </ul>
                            </Container></div>
                    }
                    
                    { article.body_introduction && 
                        <Container>
                            <div className={styles.centeredAd} dangerouslySetInnerHTML={{ __html: `<div id="ezoic-pub-ad-placeholder-102"> </div>`}}/>
                        </Container>
                    }

                    {
                    // add children posts if any
                    article.children && article.children.length > 0 && (
                        <BlogPostPreviewList
                            nodes={article.children}
                            max_n="100"
                            page_type={article.page_type}
                            ad_ids={ad_ids}
                        />
                    )}

                    {
                        // show affiliate notice
                        is_affiliate && is_affiliate.length > 0 &&
                        <div><Container>
                            <OfferAffiliateNotice></OfferAffiliateNotice>
                        </Container></div>
                    }

                    {
                        // display body components
                        article.body && article.body.map((component, index) => {
                            // increment
                            var anchor = null
                            var indexText = ""
                            var show_ad = false
                            if ((index+1) % 2 === 0){
                                nth_ad = nth_ad + 1
                                show_ad = true
                            }
                            if (component.title){
                                anchor = string_to_slug( component.title )
                                sectionNumber = sectionNumber + 1
                                indexText = sectionNumber+". "
                            }
                            if (component.Question){
                                anchor = string_to_slug( component.Question )
                                sectionNumber = sectionNumber + 1
                                indexText = sectionNumber+". "
                            }
                            return (component && 
                                <>
                                    { show_ad
                                        ? <>
                                            <div className={styles.sectionFullwidth} key={`ad_`+nth_ad}>
                                                <div className={styles.centeredAd} dangerouslySetInnerHTML={{ __html: `
                                                    <div id="ezoic-pub-ad-placeholder-`+ad_ids[nth_ad]+`"> </div>`}}/>
                                            </div>
                                        </>
                                        : null
                                    }
                                    <DynamicZoneComponent component={component} index={indexText} anchor_id={anchor}></DynamicZoneComponent>
                                </>
                            )
                        }
                        )
                    }
                <div><Container>
                <Markdown children={article.body_conclusion} skipHtml={true} />
                </Container>
                <Container>
                    <ArticleFooter/>
                    {search_index && <SearchBar/>}
                </Container></div>
            </ArticleWrapper>
            <Container>
            </Container>
            

            {
                // related pages
                article.related_pages && article.related_pages.length > 0 && (
                <RelatedPages nodes={article.related_pages}></RelatedPages>
            )}
            {process.env.EZOIC_ACCOUNT_ID && 
              <>
                <div className={styles.centeredAd} dangerouslySetInnerHTML={{ __html: `
                <div id="ezoic-pub-ad-placeholder-106"> </div>
                `}}/>
              </>
            }
        
            {process.env.ADSENSE_CLIENT_ID && 
                <div className={styles.centeredAd}>
                <span dangerouslySetInnerHTML={{ __html: `<!-- side-top --> <ins class="adsbygoogle" style="display:block" data-ad-client="`+process.env.ADSENSE_CLIENT_ID+`" data-ad-slot="`+"1219298507"+`" data-ad-format="auto" data-full-width-responsive="true"></ins><script>(adsbygoogle = window.adsbygoogle || []).push({});</script>`}}/>
                </div>
            }
            
    </>
  )
}

export default BlogPost
