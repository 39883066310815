import React from 'react'

import { graphql, Link } from 'gatsby'

import {getPageUrl} from '../lib/helpers'

import AboutSideCallout from './about-sidecallout.js'

import * as styles from './article-wrapper.module.css'

const ArticleWrapper = ({article, children, ezoic_ad_ids, show_about_sidecallout = true}) => {

  if (!ezoic_ad_ids || ezoic_ad_ids.length == 0){
    ezoic_ad_ids = ["101","104"]
  }
  var adsense_ad_ids = ["1676918912", "9508658542"]

  return (
        <div className={styles.root}>
            <div itemScope itemType="https://schema.org/mainContentOfPage" id="mainContentOfPage" className={styles.main}>{children}</div>
            <div className={styles.rightPanel}>
              <div className={styles.rightPanelPadded}>
                {show_about_sidecallout && (
                  <div>
                    <AboutSideCallout></AboutSideCallout>
                  </div>
                )}
                { article.related_pages && article.related_pages.length > 0 &&
                <div>
                  <span className={styles.rightSubmenu}>Related</span>
                  <ul className={styles.similarPages}>
                  {article.related_pages.map(post => post && (
                      <li key={`similar_${post.id}_${post.slug}`} className={styles.listItem}>
                        <Link to={getPageUrl(post)}>{post.title_short ? post.title_short : post.title}</Link>
                      </li>
                  ))}
                  </ul>
                </div>
                }
                { article.trending_pages && article.trending_pages.length > 0 &&
                <div>
                  <span className={styles.rightSubmenu}>Trending</span>
                  <ul className={styles.similarPages}>
                  {article.trending_pages.map(post => post && (
                      <li key={`similar_${post.id}_${post.slug}`} className={styles.listItem}>
                        <Link to={getPageUrl(post)}>{post.title}</Link>
                      </li>
                  ))}
                  </ul>
                </div>
                }
                { article.categorytopic_pages && article.categorytopic_pages.length > 0 &&
                <div>
                  <span className={styles.rightSubmenu}>Topics</span>
                  <ul className={styles.relatedTopics}>
                  {article.categorytopic_pages.map(topic => topic && (
                      <li key={`topic_${topic.id}`} className={styles.listItem}>
                        <Link to={getPageUrl(topic)}>{topic.primary_topic ? topic.primary_topic : topic.title_short}</Link>
                      </li>
                  ))}
                  </ul>
                </div>
                }
                <div className={styles.stickableArea}>
                  <div className={styles.stickyMenu}>
                    {process.env.ADSENSE_CLIENT_ID && 
                      <>
                      <div dangerouslySetInnerHTML={{ __html: `<!-- side-top --> <ins class="adsbygoogle" style="display:inline-block;width:300px;height:250px" data-ad-client="`+process.env.ADSENSE_CLIENT_ID+`" data-ad-slot="`+adsense_ad_ids[0]+`"></ins><script>(adsbygoogle = window.adsbygoogle || []).push({});</script>`}}/>
                      <div dangerouslySetInnerHTML={{ __html: `<!-- side-top --> <ins class="adsbygoogle" style="display:inline-block;width:300px;height:600px" data-ad-client="`+process.env.ADSENSE_CLIENT_ID+`" data-ad-slot="`+adsense_ad_ids[1]+`"></ins><script>(adsbygoogle = window.adsbygoogle || []).push({});</script>`}}/>
                      </>
                    }

                    {process.env.EZOIC_ACCOUNT_ID && 
                      <>
                      <span dangerouslySetInnerHTML={{ __html: `<div id="ezoic-pub-ad-placeholder-`+ezoic_ad_ids[0]+`"> </div>`}}/>
                      <span dangerouslySetInnerHTML={{ __html: `<div id="ezoic-pub-ad-placeholder-`+ezoic_ad_ids[1]+`"> </div>`}}/>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
        </div>
  )
}

export default ArticleWrapper
